import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabSelectorComponent } from './tab-selector.component';

@NgModule({
  declarations: [TabSelectorComponent],
  imports: [
    CommonModule
  ],
  exports: [TabSelectorComponent]
})
export class TabSelectorModule { }
